:root {
  --green: #006600;
  --red: #bb0000;
}

body {
  font-family: "Encode Sans", sans-serif !important;
}
* {
  margin: 0;
  line-height: 1.4;
}

html,
body {
  position: relative;
  min-height: 100%;
}

.label {
  font-size: 13px;
}

.text-success {
  color: var(--green) !important;
}
.text-danger {
  color: var(--red) !important;
}
/* #form{
  margin-top: 3rem
} */

.main-img {
  display: block;
  max-width: 120px;
  max-height: 350px;
  width: auto;
  height: auto;

}
.main-title {
  color: var(--green);
  font-size: 32px;
  font-weight: bold;
  padding: 1em 0 0.5em 0;
}
.main-div {
  padding-top: 2em !important;
  padding-left: 2em !important;
  padding-right: 2em !important;

}
.main-subtitle {
  font-size: 20px;
  font-weight: 400;
}
.circular {
  width: 80px;
  height: 80px;
  border-radius: 0 0 0 90px;
  position: absolute;
  right: 0;
  background-color: var(--red);
}
.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 0;
  flex-wrap: wrap;
}
.banner img {
  max-width: 200px;
  max-height: 100px;
  width: auto;
  height: fit-content;
  display: inline;
  margin: 0 1em;
}

.title {
  color: var(--red);
  text-align: center;
  font-weight: bold;
  margin: 0;
}
.banner p {
  color: var(--green);
  font-weight: bold;
  font-size: 24px;
  margin: 0;
}
.card {
  box-shadow: 0 0 5px #f2f2f2;
  border-radius: 8px !important;
}
.padded-title {
  padding-bottom: 1em;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border: 1px solid var(--green) !important;
  outline: 0;
  box-shadow: none !important;
}

.foot{
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: #f0f2f5;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.ant-steps-item-custom.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon,
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--green) !important;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: var(--green) !important;
}
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-title,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-subtitle,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-description,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-icon
  .ant-steps-icon {
  color: var(--red) !important;
}
.footer {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding-right: 15px;
  padding-left: 15px;
}
.anticon {
  vertical-align: 0em !important;
}
.card-body a {
  text-decoration: none;
}
.content {
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 80px;
}

/* RESPONSIVE STYLES */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  .foot{
    position: relative;
  }
  /* Main Page */
  .main-img {
    max-width: 250px;
    max-height: 100px;
    width: auto;
    height: auto;
  }
  .main-div {
    padding-left: 2em !important;
    padding-right: 2em !important;
    padding-top: 2em !important;
  }
  .header-image {
    display: flex;
    justify-content: center;
  }
  .main-title {
    font-size: 28px;
  }
  .main-subtitle {
    font-size: 18px;
  }
  .banner {
    display: block;
    padding: 0.5rem 0 2rem 0;
  }
  .circular {
    width: 60px;
    height: 60px;
  }
  .banner p {
    font-size: 18px;
    padding: 1em 0 0 0;
    text-align: center;
  }
  .card-body {
    padding: 1.25rem 0.5rem !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 400px) {
  .main-div {
    padding-left: 4em;
    padding-right: 4em;
    padding-bottom: 2em !important;
  }
  .foot{
    position: fixed;
  }

}

input.PhoneInputInput {
  padding: 0.4em;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

input.otp-input.text-dark {
  min-width: 40px;
  /* padding: .375rem .75rem; */
  font-size: 1rem;
  font-weight: 400;
  line-height: 3;
  /* color: #495057 !important; */
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
 .ant-layout-footer {
  text-align: center;
  bottom: 0 !important;
  width: 100% !important;
}

.ant-layout {
  background: #ffffff !important;
}

.british q {
  quotes: "‘" "’" "“" "”";
}
.ant-btn  {
  background: #dc3545;
  border-color: #dc3545
}

.ant-btn-primary {
  color: #fff;
  background: #ee020b !important;
  border-color: #ee020b !important;
}
.success_status{
  color: green;
  font-weight: bold;
}

.failed_status{
  color: red;
  font-weight: bold;
}

.center_success{
  justify-content: center;
  align-items: center;
  display: flex;
}

.ant-tag-success {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
  font-size: larger !important;
  padding: 6px;
}
.ant-tag-processing {
  font-size: larger !important;
  padding: 6px !important;
}

