.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: #f0f2f5;
  position: fixed;
  bottom: 0;
}

.ant-btn .ant-btn-primary {
  background: #dc3545;
  border-color: #dc3545
}

/*.footer {*/
/*  !*position: fixed;*!*/
/*  left: 0;*/
/*  bottom: 0;*/
/*  width: 100%;*/
/*  background-color: red;*/
/*  color: white;*/
/*  text-align: center;*/
/*}*/

.id{
  color: #585859;
}
.response{
  color: #163F65;
  text-transform: uppercase;
  font-size: x-large;
  font-style: normal;
}

